import { ETypeVehicleId, ETypeVehicleIcon } from '../models';

export const getTypeVehicleIcon = (typeVehicleId: ETypeVehicleId): string => {
  const ICONS: Record<ETypeVehicleId, ETypeVehicleIcon> = {
    [ETypeVehicleId.BICYCLE]: ETypeVehicleIcon.BICYCLE,
    [ETypeVehicleId.CAR]: ETypeVehicleIcon.CAR,
    [ETypeVehicleId.MOTORCYCLE]: ETypeVehicleIcon.MOTORCYCLE,
  };

  return ICONS[typeVehicleId];
};
