import {
  ECountry,
  ICountryOld,
  ECountryCurrencyCode,
  ECountryCurrencySymbol,
} from '../models';

export const COUNTRIES: Record<ECountry, ICountryOld> = {
  [ECountry.COLOMBIA]: {
    currencyCode: ECountryCurrencyCode.COLOMBIA,
    currencySymbol: ECountryCurrencySymbol.COLOMBIA,
    locales: 'es-CO',
    placeholder: `${ECountryCurrencyCode.COLOMBIA} ${ECountryCurrencySymbol.COLOMBIA}0`,
  },

  [ECountry.ENGLAND]: {
    currencyCode: ECountryCurrencyCode.ENGLAND,
    currencySymbol: ECountryCurrencySymbol.ENGLAND,
    locales: 'en-GB',
    placeholder: `${ECountryCurrencyCode.ENGLAND} ${ECountryCurrencySymbol.ENGLAND}0`,
  },

  [ECountry.MEXICO]: {
    currencyCode: ECountryCurrencyCode.MEXICO,
    currencySymbol: ECountryCurrencySymbol.MEXICO,
    locales: 'es-MX',
    placeholder: `${ECountryCurrencyCode.MEXICO} ${ECountryCurrencySymbol.MEXICO}0`,
  },
};
