import { ECountry } from './country.model';

/**
 * @deprecated
 */
export enum EPhoneCode {
  COLOMBIA = '57',
  ENGLAND = '44',
  MEXICO = '52',
}

/**
 * @deprecated
 */
export interface IPhoneCodeOld {
  country: ECountry;
  label: string;
  value: EPhoneCode;
}

export interface IPhoneCode {
  emojiFlag: string;
  id: number;
  phoneCode: string;
}

/**
 * @deprecated
 */
export const PHONE_CODES: IPhoneCodeOld[] = [
  {
    country: ECountry.COLOMBIA,
    label: `🇨🇴 +${EPhoneCode.COLOMBIA}`,
    value: EPhoneCode.COLOMBIA,
  },
  {
    country: ECountry.ENGLAND,
    label: `🇬🇧 +${EPhoneCode.ENGLAND}`,
    value: EPhoneCode.ENGLAND,
  },
  {
    country: ECountry.MEXICO,
    label: `🇲🇽 +${EPhoneCode.MEXICO}`,
    value: EPhoneCode.MEXICO,
  },
];
