export class PString {
  static getFullName(firstName: string, lastName: string): string {
    return this.toCapitalize(`${firstName} ${lastName}`);
  }

  static getFullPhone = (phoneCode: string, phone: string): string => {
    return phone ? `+${phoneCode} ${phone}` : 'common.strings.undefined';
  };

  /**
   * Capitalize the first letter of a word.
   *
   * Example: nIkOlA DEv => Nikola Dev
   * @param {string} string - string - The word to capitalize.
   */
  static toCapitalize(string: string): string {
    if (!string) return '';

    return String(string)
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, (w: string): string =>
        w.replace(/^\w/, (c: string): string => c.toUpperCase()),
      );
  }

  static verifyProperty<T>(property?: T): T | string {
    return property ? property : 'common.strings.undefined';
  }

  /**
   * Obfuscates the given payload and shows only the last 4 characters.
   * If `obfuscateDriverDataFF` is `false`, the payload is returned as is.
   *
   * Example: 3117742525 => **********2525
   * @param payload - The payload to obfuscate.
   * @param obfuscateDriverDataFF - A boolean indicating whether to obfuscate the payload or not. Default is `false`.
   * @returns The obfuscated payload with only the last 4 characters visible.
   */
  static obfuscateAndShowLast4Characters(
    payload: string | number,
    obfuscateDriverDataFF: boolean = false,
  ): string {
    if (!obfuscateDriverDataFF) return payload.toString();

    const payloadString = payload.toString();

    if (payloadString.length < 4) return payloadString;

    return '*'.repeat(payloadString.length - 4) + payloadString.slice(-4);
  }

  /**
   * Obfuscates an email address by replacing characters in the name portion with asterisks.
   * If `obfuscateDriverDataFF` is `false`, the email address is returned in lowercase.
   *
   * Example: juan.merchan@parqco.com => j**********n@parqco.com
   * @param email - The email address to obfuscate.
   * @param obfuscateDriverDataFF - A boolean indicating whether to obfuscate the email address or not.
   * @returns The obfuscated email address.
   */
  static obfuscateEmail(
    email: string,
    obfuscateDriverDataFF: boolean = false,
  ): string {
    if (!obfuscateDriverDataFF) return email.toLowerCase();

    const [name, domain] = email.toLocaleLowerCase().split('@');
    const obfuscatedName = '*'.repeat(name.length - 2);
    const nameFirstChar = name.charAt(0);
    const nameLastChar = name.charAt(name.length - 1);

    return `${nameFirstChar}${obfuscatedName}${nameLastChar}@${domain}`;
  }

  /**
   * Obfuscates the given payload by replacing its characters with asterisks.
   * If `obfuscateDriverDataFF` is `false`, the payload is returned as is.
   *
   * Example: NikolaM-Dev => ************
   * @param payload The payload to obfuscate.
   * @param obfuscateDriverDataFF Flag indicating whether to obfuscate the payload or not.
   * @returns The obfuscated payload.
   */
  static obfuscateFull(
    payload: string | number,
    obfuscateDriverDataFF: boolean = false,
  ): string {
    if (!obfuscateDriverDataFF) return payload.toString();

    const payloadString = payload.toString();

    return '*'.repeat(payloadString.length);
  }
}
