import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '../../services';

@Injectable({ providedIn: 'root' })
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate() {
    const isAuth = this.authService.isLogin();

    if (isAuth) return true;

    this.router.navigate(['/sign-in']);

    return false;
  }
}
