import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { CanonicalService } from './services/canonical.service';

declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  private ALLOWED_LANGS = ['en', 'es'];
  public agree_cookie = false;
  public title = 'ParQ-web';

  constructor(
    translateService: TranslateService,
    private readonly metaTagService: Meta,
    private readonly canonicalService: CanonicalService,
  ) {
    const lang = translateService.getBrowserLang();

    if (this.ALLOWED_LANGS.indexOf(lang) >= 0) translateService.use(lang);
  }

  onActivate(): void {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    this.metaTagService.addTags([
      {
        name: 'keywords',
        content: 'Parqueadero, Parking, PARQ, Estacionamiento',
      },
      { name: 'robots', content: 'index, follow, operator, driver' },
      { name: 'author', content: 'PARQ Colombia SAS' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-06-28', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
    ]);
    this.canonicalService.setCanonicalURL();

    jQuery('#close-cookies').click(function () {
      jQuery('.cookies').remove();
    });

    this.agree_cookie = localStorage.getItem('agree_cookies') ? true : false;
  }

  public agreeCookies(): void {
    localStorage.setItem('agree_cookies', 'true');
    this.agree_cookie = true;
  }
}
