import { Injectable } from '@angular/core';

import {
  IDeletePayload,
  IGetPayload,
  IParqServiceRepository,
  IPatchPayload,
  IPostPayload,
} from './models';
import { environment } from '../../../../environments/environment';
import { ParqService } from './parq.service';
import { HttpClient } from '@angular/common/http';
import { LocalAuthService } from '../auth';

@Injectable({ providedIn: 'root' })
export class EntitiesApiService implements IParqServiceRepository {
  private readonly _parqService: ParqService = new ParqService(
    this.http,
    this.localAuthService,
  );

  constructor(
    private readonly http: HttpClient,
    private readonly localAuthService: LocalAuthService,
  ) {
    this.onInit();
  }

  private onInit(): void {
    const baseURL: string = environment.microliths.entities;
    const localBaseURL: string = 'http://localhost:9002/api/entities/v2';

    this._parqService.setAttributes({ baseURL, localBaseURL });
  }

  delete<R>(payload: IDeletePayload): Promise<R> {
    return this._parqService.delete(payload);
  }

  get<R>(payload: IGetPayload): Promise<R> {
    return this._parqService.get(payload);
  }

  patch<R, P>(payload: IPatchPayload<P>): Promise<R> {
    return this._parqService.patch(payload);
  }

  post<R, P>(payload: IPostPayload<P>): Promise<R> {
    return this._parqService.post(payload);
  }
}
