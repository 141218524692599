import { ETypeParking } from './type-parking.model';
import { ILocationTypeVehicle } from './location-type-vehicle.model';
import { IParkingService } from './parking-service.model';
import { ISchedule } from './schedule.model';
import { ISpecialRate } from './special-rate.model';

export interface ILocation {
  address: string;
  allowDebtFF: boolean;
  city: string;
  companyId: number;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  contactPhoneCode: string;
  country: string;
  createdAt: string;
  description: string;
  email: string;
  geolocation: IGeolocation;
  id: number;
  insuranceCompany?: string;
  insurancePolicyNumber?: string;
  isActive: boolean;
  locationTypeVehicles: ILocationTypeVehicle[];
  monthlyInstallments: boolean;
  name: string;
  obfuscateDriverDataFF: boolean;
  offStreetCheckOutAlgorithm: EOffStreetCheckOutAlgorithm;
  parkingServices: IParkingService[];
  paymentType: ELocationPaymentType;
  ratesIncludeVat: boolean;
  schedules: ISchedule[];
  specialRates: ISpecialRate[];
  typeParking: ETypeParking;
  updatedAt: string;
  useCheckInWithoutDriverFF: boolean;
}

export interface IGeolocation {
  lat: number;
  lng: number;
}

export enum ELocationPaymentType {
  POST_PAY = 'post_pay',
  PRE_PAY = 'pre_pay',
}

export enum EOffStreetCheckOutAlgorithm {
  DEFAULT = 'default',
  NIZA = 'niza',
}
