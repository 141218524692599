import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { encodeToBase64 } from '../../utilities';
import { IGoToDetailPayload } from './models';

@Injectable({ providedIn: 'root' })
export class RouterService {
  constructor(private readonly router: Router) {}

  goToDetail({ id, slug, param }: IGoToDetailPayload): void {
    this.router.navigate([slug], {
      queryParams: { [param]: encodeToBase64(id) },
    });
  }

  gotToCreate(slug: string): void {
    this.router.navigate([slug]);
  }
}
