import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { EToastType } from '../models';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
  ) {}

  show(type: EToastType, rawMsg: string): void {
    const message: string = this.translateService.instant(rawMsg);

    const CHOICES: Record<EToastType, () => void> = {
      [EToastType.ERROR]: () => {
        this.toastrService.error(message);
      },
      [EToastType.INFO]: () => {
        this.toastrService.info(message);
      },
      [EToastType.SUCCESS]: () => {
        this.toastrService.success(message);
      },
      [EToastType.WARNING]: () => {
        this.toastrService.warning(message);
      },
    };

    CHOICES[type]();
  }

  showWithTitle(type: EToastType, rawTitle: string, rawMsg: string): void {
    const message: string = this.translateService.instant(rawMsg);
    const title: string = this.translateService.instant(rawTitle);

    const CHOICES: Record<EToastType, () => void> = {
      [EToastType.ERROR]: () => {
        this.toastrService.error(message, title);
      },
      [EToastType.INFO]: () => {
        this.toastrService.info(message, title);
      },
      [EToastType.SUCCESS]: () => {
        this.toastrService.success(message, title);
      },
      [EToastType.WARNING]: () => {
        this.toastrService.warning(message, title);
      },
    };

    CHOICES[type]();
  }

  info(rawMsg: string): void {
    const message: string = this.translateService.instant(rawMsg);

    this.toastrService.info(message);
  }

  warn(rawMsg: string): void {
    const message: string = this.translateService.instant(rawMsg);

    this.toastrService.warning(message);
  }

  error(rawMsg: string): void {
    const message: string = this.translateService.instant(rawMsg);

    this.toastrService.error(message);
  }

  success(rawMsg: string): void {
    const message: string = this.translateService.instant(rawMsg);

    this.toastrService.success(message);
  }
}
