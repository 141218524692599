import {
  FormGroup,
  AbstractControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FormsService {
  paginationLimitForm: FormGroup;
  qForm: FormGroup;

  private _callback: () => void;
  private _formBuilder: FormBuilder;

  getPropertyControl(form: FormGroup, property: string): AbstractControl {
    return form.get(property);
  }

  getProperty(form: FormGroup, property: string): string | number | boolean {
    return this.getPropertyControl(form, property).value;
  }

  isInvalidProperty(form: FormGroup, property: string): boolean {
    const propertyControl = this.getPropertyControl(form, property);

    return propertyControl.invalid && propertyControl.touched;
  }

  isValidForm(form: FormGroup, activeDebugMode?: boolean): boolean {
    if (form.valid) return true;

    form.markAllAsTouched();

    if (activeDebugMode)
      console.error({
        controls: form.controls,
        errors: form.errors,
        values: form.value,
      });

    return false;
  }

  initPaginationLimitForm({
    limit = 10,
    page = 1,
  }: {
    limit?: number;
    page?: number;
  } = {}): void {
    this.paginationLimitForm = this._formBuilder.group({
      limit: [limit, [Validators.required]],
      page: [page, [Validators.required]],
      totalPages: [1, [Validators.required]],
    });
  }

  initQForm(): void {
    this.qForm = this._formBuilder.group({ q: [''] });
  }

  async onChangeLimit(): Promise<void> {
    this._callback();

    this.onCorrectPage();
  }

  onSubmitQ(): void {
    this._callback();

    this.onCorrectPage();
  }

  onPreviuosPage(): void {
    if (this.page === 1) return;

    this.page -= 1;

    this._callback();
  }

  onNextPage(): void {
    if (this.page === this.totalPages) return;

    this.page += 1;

    this._callback();
  }

  private onCorrectPage(): void {
    this.page = 1;
  }

  get page(): number {
    return this.getProperty(this.paginationLimitForm, 'page') as number;
  }

  get q(): string {
    const rawQ = this.getProperty(this.qForm, 'q') as string;

    return rawQ.toLowerCase().trim();
  }

  get limit(): number {
    return this.getProperty(this.paginationLimitForm, 'limit') as number;
  }

  set page(page: number) {
    this.paginationLimitForm.patchValue({ page });
  }

  get totalPages(): number {
    return this.getProperty(this.paginationLimitForm, 'totalPages') as number;
  }

  set totalPages(totalPages: number) {
    this.paginationLimitForm.patchValue({ totalPages });
  }

  set formBuilder(formBuilder: FormBuilder) {
    this._formBuilder = formBuilder;
  }

  set callback(callback: () => void) {
    this._callback = callback;
  }
}
