export interface IRole {
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

export enum ERoleId {
  ADMIN = 1,
  OPERATOR = 2,
  STAFF = 3,
  DRIVER = 4,
  GUEST = 5,
}

export enum ERoleName {
  ADMIN = 'admin',
  DRIVER = 'driver',
  GUEST = 'guest',
  OPERATOR = 'operator',
  STAFF = 'staff',
}

export enum EDriverRoleId {
  DRIVER = 4,
  GUEST = 5,
}

export interface IDriverRole {
  label: string;
  value: EDriverRoleId;
}

export const DRIVER_ROLES = [
  { label: 'common.models.driver', value: EDriverRoleId.DRIVER },
  { label: 'common.models.guest', value: EDriverRoleId.GUEST },
];
