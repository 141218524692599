import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { EHttpStatus } from '../models';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly INVALID_CREDENTIALS =
    'The login email or password does not match.';

  constructor(
    private readonly router: Router,
    private readonly toastrService: ToastrService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse): Observable<never> => {
        if (!error) return;

        if (this.hasInvalidCredentials(error)) return;

        const INVALID_STATES = [
          EHttpStatus.UNAUTHORIZED,
          EHttpStatus.FORBIDDEN,
        ];

        if (INVALID_STATES.includes(error.status)) this.logout();

        return throwError(error);
      }),
    );
  }

  private hasInvalidCredentials(error: HttpErrorResponse): boolean {
    if (!error.error) return false;
    if (!error.error.message) return false;

    return error.error.message === this.INVALID_CREDENTIALS;
  }

  private logout(): void {
    sessionStorage.clear();

    this.toastrService.info(
      'La sesión expiró, por favor vuelve a iniciar sesión para continuar',
    );
    this.router.navigate(['/sign-in']);
  }
}
