import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ICompany } from '../types';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  async login(email: string, password: string): Promise<any> {
    const request = await this.http
      .post<any>(`${environment.apiParq}/users/v2/auth/login/operator`, {
        email: email.toLowerCase().trim(),
        password: password,
      })
      .pipe(map((res) => res))
      .toPromise();

    sessionStorage.setItem('authToken', request.accessToken);

    if (!request.error) {
      sessionStorage.setItem('authToken', request.accessToken);
      sessionStorage.setItem('userId', request.user.id);
    }

    return request;
  }

  public isLogin(): boolean {
    let accessToken = sessionStorage.getItem('authToken');
    return Boolean(accessToken);
  }

  newIsLogin(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user'));

    if (!user) return false;

    return user.accessToken ? true : false;
  }

  getAuthToken(): string {
    return sessionStorage.getItem('authToken');
  }

  getUserId(): string {
    return sessionStorage.getItem('userId');
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  public getAuthUser(): Record<string, any> {
    return JSON.parse(sessionStorage.getItem('user'))?.user;
  }

  getCompany() {
    return JSON.parse(sessionStorage.getItem('company'));
  }

  setCompany(company: ICompany): void {
    sessionStorage.setItem('company', JSON.stringify(company));
  }

  getVerificationData() {
    return JSON.parse(sessionStorage.getItem('dataForVerification'));
  }

  getGuestEmail(code: string, phone: string) {
    return code.slice(1) + phone + environment.email;
  }

  setUser(user): void {
    const accessToken = this.getAuthToken();
    sessionStorage.setItem('user', JSON.stringify({ accessToken, user }));
  }

  logout(): void {
    sessionStorage.clear();
  }

  public signOutAndRedirect(): void {
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
