import { IPhoneCode } from '../models';

export class PPhoneCode {
  static getPhoneCodeLabel(phoneCode: IPhoneCode): string {
    return `${phoneCode.emojiFlag} +${phoneCode.phoneCode}`;
  }

  static getDefaultPhoneCode(
    phoneCodes: IPhoneCode[],
    countryId: number,
  ): string {
    const defaultPhoneCode = phoneCodes.find(
      (phoneCode: IPhoneCode): boolean => phoneCode.id === countryId,
    );

    return defaultPhoneCode.phoneCode;
  }
}
