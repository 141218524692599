import { IHttpErrorResponse } from '../models';

type ToPromise<T> = Promise<[IHttpErrorResponse | null, T | null]>;

export class PError {
  static async tryAsync<T>(promise: Promise<T>): ToPromise<T> {
    try {
      const data = await promise;

      return [null, data];
    } catch (err) {
      return [err as IHttpErrorResponse, null];
    }
  }

  static hasError(error: IHttpErrorResponse | null): boolean {
    return error !== null;
  }
}
