export enum EServiceType {
  CUSTOM_SERVICE = 'custom_service',
  MONTHLY_PAYMENT = 'monthly_payment',
  PARKING_SERVICE = 'parking_service',
}

export interface IStaffCreatePurchaseOrderPayload {
  readonly amount: number;
  readonly driverId: number;
  readonly extraData: string;
  readonly serviceId: number;
  readonly serviceType: EServiceType;
  readonly vehicleId: number;
}

export interface IPurchaseOrder {
  readonly amount: number;
  readonly checkOutLink?: string;
  readonly currencyCode: string;
  readonly driverId: number;
  readonly expirationDate: string;
  readonly extraData: string;
  readonly id: number;
  readonly orderId: string;
  readonly paymentGatewayId: number;
  readonly serviceId: number;
  readonly serviceType: EServiceType;
  readonly status: string;
  readonly vehicleId: number;
}
