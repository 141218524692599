import { Pipe, PipeTransform } from '@angular/core';

import { COUNTRIES } from '../constants';
import { ECountry } from '../models';

@Pipe({ name: 'pCurrency' })
export class PCurrencyPipe implements PipeTransform {
  transform(amount: number | string, company?: { country: ECountry }): string {
    if (!company)
      return `${COUNTRIES[ECountry.COLOMBIA].currencyCode} ${
        COUNTRIES[ECountry.COLOMBIA].currencyCode
      }---`;

    const { currencyCode, currencySymbol, locales } =
      COUNTRIES[company.country] || COUNTRIES[ECountry.COLOMBIA];

    const formatter = new Intl.NumberFormat(locales, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (amount === null || amount === undefined)
      return `${currencyCode} ${currencySymbol}---`;

    return `${currencyCode} ${currencySymbol}${formatter.format(
      Number(amount),
    )}`;
  }
}
