<div
  class="modal fade"
  [id]="modalId"
  tabindex="-1"
  aria-labelledby="notificationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <h4 class="modal-title" id="notificationLabel">
          {{ 'common.components.confirmationModal.title' | translate }}
        </h4>

        <hr />
        <button
          type="button"
          class="close mb-4"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <p>{{ msg | translate }}</p>
      </div>
      <div class="modal-footer">
        <button data-dismiss="modal" class="btn btn-secondary" type="button">
          {{ 'common.components.confirmationModal.cancel' | translate }}
        </button>

        <button
          (click)="onConfirm()"
          class="btn btn-primary"
          data-dismiss="modal"
          type="button"
        >
          {{ 'common.components.confirmationModal.confirm' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
