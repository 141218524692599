import { TranslateService } from '@ngx-translate/core';

import { ECountry } from '../models';

export class PCountry {
  static defaultCountry = ECountry.COLOMBIA;

  static translate(payload: {
    country?: ECountry;
    key: string;
    translateService: TranslateService;
  }): string {
    const { country, key, translateService } = payload;
    let translateKey = `${key}.`;

    if (!country) translateKey += this.defaultCountry;
    else translateKey += country;

    return translateService.instant(translateKey) as string;
  }
}
