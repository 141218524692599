import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  IDeletePayload,
  IGetPayload,
  IParqServiceRepository,
  IPatchPayload,
  IPostPayload,
  ISetAttributesPayload,
} from './models';
import { LocalAuthService } from '../auth';

@Injectable({ providedIn: 'root' })
export class ParqService implements IParqServiceRepository {
  private baseURL: string;
  private localBaseURL: string;

  constructor(
    private readonly http: HttpClient,
    private readonly localAuthService: LocalAuthService,
  ) {}

  setAttributes({ baseURL, localBaseURL }: ISetAttributesPayload): void {
    this.baseURL = baseURL;
    this.localBaseURL = localBaseURL;
  }

  delete<R>({
    params = null,
    path,
    useLocalUrl = false,
  }: IDeletePayload): Promise<R> {
    const baseURL = this.getBaseURL(useLocalUrl);

    return this.http
      .delete<R>(`${baseURL}/${path}`, {
        headers: this.headers,
        params,
      })
      .toPromise();
  }

  get<R>({
    params = null,
    path,
    useLocalUrl = false,
  }: IGetPayload): Promise<R> {
    const baseURL = this.getBaseURL(useLocalUrl);

    return this.http
      .get<R>(`${baseURL}/${path}`, { headers: this.headers, params })
      .toPromise();
  }

  patch<R, P>({
    params = null,
    path,
    payload = null,
    useLocalUrl = false,
  }: IPatchPayload<P>): Promise<R> {
    const baseURL = this.getBaseURL(useLocalUrl);

    return this.http
      .patch<R>(`${baseURL}/${path}`, payload, {
        headers: this.headers,
        params,
      })
      .toPromise();
  }

  post<R, P>({
    params = null,
    path,
    payload = null,
    useLocalUrl = false,
  }: IPostPayload<P>): Promise<R> {
    const baseURL = this.getBaseURL(useLocalUrl);

    return this.http
      .post<R>(`${baseURL}/${path}`, payload, {
        headers: this.headers,
        params,
      })
      .toPromise();
  }

  private getBaseURL(useLocalUrl: boolean): string {
    return useLocalUrl ? this.localBaseURL : this.baseURL;
  }

  get headers(): HttpHeaders {
    return this.localAuthService.headers;
  }
}
