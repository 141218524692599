import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmationModalComponent } from './confirmation-modal.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild({})],
  declarations: [ConfirmationModalComponent],
  exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
