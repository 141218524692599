export class PTime {
  private static readonly MIN_IN_MS = 60 * 1000;
  /**
   * Formats a given total number of minutes into a string representation of days, hours, and minutes.
   *
   * @param totalMinutes - The total number of minutes to be formatted. If not provided, defaults to '00D 00H 00M'.
   * @returns A string in the format `DDH HHM`, where `D` represents days, `H` represents hours, and `M` represents minutes.
   *
   * @example
   * // Example with 1500 minutes (equivalent to 1 day, 1 hour, and 0 minutes):
   * const result = getFormattedTotalMinutes(1500);
   * console.debug(result); // Outputs: "01D 01H 00M"
   *
   * @example
   * // Example with undefined input:
   * const result = getFormattedTotalMinutes();
   * console.debug(result); // Outputs: "00D 00H 00M"
   *
   * @description Converts the given total minutes into days, hours, and minutes. Pads each value with leading zeros to
   * ensure a two-digit format. If `totalMinutes` is undefined or falsy, it defaults to '00D 00H 00M'.
   */
  static getFormattedTotalMinutes(totalMinutes?: number): string {
    if (!totalMinutes) return '00D 00H 00M';

    const days = Math.floor(totalMinutes / 1440);
    const hours = Math.floor((totalMinutes % 1440) / 60);
    const minutes = totalMinutes % 60;

    const formattedDays = String(days).padStart(2, '0');
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedDays}D ${formattedHours}H ${formattedMinutes}M`;
  }

  /**
   * Delays the execution for a specified amount of time.
   * @param ms - The number of milliseconds to wait. Default is 200.
   * @returns A promise that resolves after the specified delay.
   */
  static wait = (ms = 200): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

  /**
   * Calculates the total parking time in minutes between a start and end date.
   *
   * @param startDate - The starting point of the parking period.
   * @param endDate - The ending point of the parking period.
   *
   * @returns The total parking time in minutes, rounded up to the nearest minute.
   *
   * @example
   * // Example with a 90.5-minute difference:
   * const totalMinutes = getParkingTotalMinutes(
   *   new Date('2024-10-11T10:00:00'),
   *   new Date('2024-10-11T11:30:30')
   * );
   * console.debug(totalMinutes); // Outputs: 91
   *
   * @description Computes the time difference between `startDate` and `endDate` in milliseconds.
   * Divides the difference by `this.MIN_IN_MS` (milliseconds per minute) and rounds up to ensure any partial minutes
   * are included in the total.
   */

  static getParkingTotalMinutes(startDate: Date, endDate: Date): number {
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    const timeDifference = endTime - startTime;

    return Math.ceil(timeDifference / this.MIN_IN_MS);
  }
}
