import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IOperator } from '../../models';
import { ToastService } from '../toast';

@Injectable({ providedIn: 'root' })
export class LocalAuthService {
  constructor(private readonly toastService: ToastService) {}

  getOperator(): IOperator {
    try {
      const rawPayload: string = sessionStorage.getItem('user');
      const operator: IOperator = JSON.parse(rawPayload).user;

      return operator;
    } catch (error) {
      console.error(error);
      this.toastService.info('common.toast.internalErrorServer');
    }
  }

  getAuthToken(): string {
    return sessionStorage.getItem('authToken');
  }

  get headers(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getAuthToken()}`,
    });

    return headers;
  }
}
