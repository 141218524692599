import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TitleService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly title: Title,
  ) {}

  setTitle(key: string): void {
    const title: string = this.translateService.instant(key);
    this.title.setTitle(`PARQ | ${title}`);
  }
}
