export enum ETypeVehicleId {
  BICYCLE = 3,
  CAR = 1,
  MOTORCYCLE = 2,
}

export enum ETypeVehicleNomenclature {
  BICYCLE = 'bicycle',
  CAR = 'car',
  MOTORCYCLE = 'motorcycle',
}

export enum ETypeVehicleIcon {
  CAR = 'fas fa-car',
  MOTORCYCLE = 'fas fa-motorcycle',
  BICYCLE = 'fas fa-bicycle',
}

export interface ITypeVehicle {
  icon: ETypeVehicleIcon;
  label: string;
  value: ETypeVehicleId;
}

export const TYPE_VEHICLES: ITypeVehicle[] = [
  {
    icon: ETypeVehicleIcon.CAR,
    label: 'common.models.car',
    value: ETypeVehicleId.CAR,
  },
  {
    icon: ETypeVehicleIcon.MOTORCYCLE,
    label: 'common.models.motorcycle',
    value: ETypeVehicleId.MOTORCYCLE,
  },
  {
    icon: ETypeVehicleIcon.BICYCLE,
    label: 'common.models.bicycle',
    value: ETypeVehicleId.BICYCLE,
  },
];
