export enum EGender {
  FEMALE = 'f',
  MALE = 'm',
  NON_BINARY = 'b',
  WITHOUT_CHOOSING = 'wc',
}

export interface IGender {
  label: string;
  value: EGender;
}

export const GENDERS: IGender[] = [
  { label: 'common.models.female', value: EGender.FEMALE },
  { label: 'common.models.male', value: EGender.MALE },
  { label: 'common.models.nonbinary', value: EGender.NON_BINARY },
  { label: 'common.models.withoutChoosing', value: EGender.WITHOUT_CHOOSING },
];
