import { HttpParams } from '@angular/common/http';

export const getParams = <P>(rawParams: P): HttpParams => {
  let params = new HttpParams();

  if (Object.entries(params).length === 0) return params;

  Object.entries(rawParams).forEach(([key, value]): void => {
    params = params.append(key, String(value));
  });

  return params;
};

export enum EParam {
  DRIVER_ID = 'ZHJpdmVySWQ',
  VEHICLE_ID = 'dmVoaWNsZUlk',
}
