export interface IShift {
  cashRegisterId: number;
  cashUpId: number;
  createdAt: string;
  endDate: string;
  endRemarks: string | null;
  id: number;
  locationId: number;
  operatorId: number;
  operatorRecieverId: number | null;
  shiftOperationId: number;
  startDate: string;
  startRemarks: string;
  status: EShiftStatus;
  updatedAt: string;
}

export enum EShiftStatus {
  CURRENT = 'current',
  FINISHED = 'finished',
}
