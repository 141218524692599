export enum ENotificationMethod {
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
}

export interface INotificationMethod {
  label: string;
  value: ENotificationMethod;
}

export const NOTIFICATION_METHODS: INotificationMethod[] = [
  { label: 'common.models.email', value: ENotificationMethod.EMAIL },
  { label: 'common.models.whatsapp', value: ENotificationMethod.WHATSAPP },
];
