<router-outlet (activate)="onActivate()"></router-outlet>
<div class="cookies text-center" *ngIf="!agree_cookie">
  <button
    id="close-cookies"
    type="button"
    class="close"
    (click)="agreeCookies()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <p class="m-0" [innerHTML]="'app.cookies_bar.paragraph' | translate"></p>
</div>
