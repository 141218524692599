import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '../../services';
import { ERoleName } from '../../common';

@Injectable({ providedIn: 'root' })
export class UnauthenticatedGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate() {
    const isAuth = this.authService.isLogin();

    if (!isAuth) return true;

    const user = this.authService.getAuthUser();

    if (user.role.name === ERoleName.STAFF)
      this.router.navigate(['/admin/operator-user/dashboard']);

    if (user.role.name === ERoleName.OPERATOR)
      this.router.navigate(['/admin/operator']);

    return false;
  }
}
