import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly CLOSE = 'X';

  constructor(private readonly snackBar: MatSnackBar) {}

  private config(css: string, withTimeout: boolean): any {
    return {
      duration: withTimeout ? 3000 : 0,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: [css],
    };
  }

  success(message: string, withTimeout = true): void {
    this.snackBar.open(
      message,
      this.CLOSE,
      this.config('green-snack', withTimeout),
    );
  }

  error(message: string, withTimeout = true): void {
    this.snackBar.open(
      message,
      this.CLOSE,
      this.config('red-snack', withTimeout),
    );
  }

  info(message: string, withTimeout = true): void {
    this.snackBar.open(
      message,
      this.CLOSE,
      this.config('info-snack', withTimeout),
    );
  }

  orange(message: string, withTimeout = true): void {
    this.snackBar.open(
      message,
      this.CLOSE,
      this.config('yellow-snack', withTimeout),
    );
  }
}
