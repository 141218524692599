import * as moment from 'moment';

export class PDate {
  /**
   * Returns the current time zone.
   *
   * @returns {string} The current time zone.
   */
  static getTimeZome(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Formats a date with Moment.js library with `DD/MM/YYYY, HH:mm a` format.
   *
   * Example: 01/01/2021, 12:00 PM
   * @param date - The date to be formatted.
   * @returns The formatted date string.
   */
  static getFormatedFullDateForReport(date: Date | string): string {
    return moment(date).format('DD/MM/YYYY, hh:mm a');
  }
}
