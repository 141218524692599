import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

export const COMMON_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule.forChild({}),
];
