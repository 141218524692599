/** @deprecated  */
export enum ECountry {
  COLOMBIA = 'colombia',
  ENGLAND = 'england',
  MEXICO = 'mexico',
}

/** @deprecated  */
export interface ICountryOld {
  readonly currencyCode: ECountryCurrencyCode;
  readonly currencySymbol: ECountryCurrencySymbol;
  readonly locales: string;
  readonly placeholder: string;
}

/** @deprecated  */
export enum ECountryCurrencyCode {
  COLOMBIA = 'COP',
  ENGLAND = 'GBP',
  MEXICO = 'MXN',
}

/** @deprecated  */
export enum ECountryCurrencySymbol {
  COLOMBIA = '$',
  ENGLAND = '£',
  MEXICO = '$',
}

export enum ECountryISO3 {
  COLOMBIA = 'COL',
  MEXICO = 'MEX',
}
