export enum ELanguage {
  SPANISH = 'es',
  ENGLISH = 'en',
}

export interface ILanguage {
  label: string;
  value: ELanguage;
}

export const LANGUAGES: ILanguage[] = [
  { label: 'common.models.spanish', value: ELanguage.SPANISH },
  { label: 'common.models.english', value: ELanguage.ENGLISH },
];
