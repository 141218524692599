import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  @Input() modalId: string;
  @Input() msg: string;
  @Input() onConfirm: () => void;
}
