export enum EIdentificationType {
  CITIZENSHIP = 'citizenship',
  FOREIGNER = 'foreigner',
  PASSPORT = 'passport',
}

export interface IIdentificationType {
  label: string;
  value: EIdentificationType;
}

export const IDENTIFICATION_TYPES: IIdentificationType[] = [
  {
    label: 'common.models.citizenship',
    value: EIdentificationType.CITIZENSHIP,
  },
  { label: 'common.models.foreigner', value: EIdentificationType.FOREIGNER },
  { label: 'common.models.passport', value: EIdentificationType.PASSPORT },
];
