import { Pipe, PipeTransform } from '@angular/core';

import { COUNTRIES } from '../constants';
import { ECountry } from '../models';

@Pipe({ name: 'pCurrencyPlaceholder' })
export class PCurrencyPlaceholderPipe implements PipeTransform {
  transform(company?: { country: ECountry }): string {
    if (!company) return COUNTRIES[ECountry.COLOMBIA].placeholder;

    return COUNTRIES[company.country].placeholder;
  }
}
